export const LOGOS = {
  NANDOS:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/nandos-01.svg',
  MOTHERLAND:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/motherland-coffee-logo.svg',
  SEATTLE:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/seattle-coffee-logo.png',
}

export const PAYMENT_METHODS = {
  KIOSK: 'Kiosk',
  DEBIT_CARD: 'Debit Card',
  CONCIERGE_DESK: 'Digital Concierge Desk',
  PAYROLL: 'Payroll',
}

export const LOCATION_OPTIONS = {
  CAMPUS: 'Campus',
  HOME: 'Home',
}

export const USER_TYPES = {
  USER: 'user',
  VENDOR: 'vendor',
  SERVICE_PROVIDER: 'service_provider',
}

export const SHIPPING_COST = 250
