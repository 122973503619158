import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ThemeProvider } from '@mui/material/styles'
import { Dialog } from '@headlessui/react'
import ReactPlayer from 'react-player'

// Icons
import { AiFillFacebook } from 'react-icons/ai'
import { GlobeIcon, PhoneIcon, MailIcon } from '@heroicons/react/solid'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { ItemCard } from '../../components/shared/ItemCard'
import { TabPanel } from '../../components/shared/TabPanel.jsx'
import { BigAdsCard } from '../../components/shared/BigAdsCard.jsx'
import { Modal } from '../../components/shared/Modal.jsx'

import { ROUTES } from '../../routes'
import { LOGOS } from '../../utils/constants'

// Hooks
import {
  usePrefetchMotherlandOptIn,
  usePretchSeattleOptIn,
} from '../../hooks/useOptIns'
import { useBigAds } from '../../hooks/useBigAds.js'

// Utils
import { theme } from '../../config/materialTheme.js'

const foodOfferingItems = [
  { name: 'Nandos', route: ROUTES.USER.NANDOS, image: LOGOS.NANDOS },
  { name: 'Seattle Coffee', route: ROUTES.USER.SEATTLE, image: LOGOS.SEATTLE },
]

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabs = [
  { label: 'Food & Beverage', index: 0 },
  { label: 'Business', index: 1 },
]

const UserOfferingsView = () => {
  const [tabValue, setTabValue] = React.useState(0)
  const [currentBigAd, setCurrentBigAd] = React.useState({})
  const [showModal, setShowModal] = React.useState(false)
  const { data: bigAds, isFetching: isFetchingBigAds } = useBigAds()
  usePretchSeattleOptIn()
  usePrefetchMotherlandOptIn()

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div>
      <Modal open={showModal} setOpen={setShowModal}>
        <Dialog.Title
          as="h3"
          className="text-2xl leading-6 font-medium text-primary-default"
        >
          {currentBigAd.name}
        </Dialog.Title>
        <Dialog.Title
          as="h5"
          className="text-lg font-light leading-6 text-tertiary-default"
        >
          {currentBigAd.business_offering}
        </Dialog.Title>
        <div className="mt-5 flex flex-col md:flex-row space-x-0 space-y-6 md:space-y-0 md:space-x-6">
          <div className="w-full md:w-1/3 space-y-3 text-secondary-default">
            <React.Fragment>
              {currentBigAd.phone_number ? (
                <div className="flex space-x-3 items-center">
                  <PhoneIcon className="block h-7 w-7" />
                  <a href={`tel:${currentBigAd.phone_number}`}>
                    {currentBigAd.phone_number}
                  </a>
                  {currentBigAd.phone_number_alt ? (
                    <a href={`tel:${currentBigAd.phone_number_alt}`}>
                      {` –   ${currentBigAd.phone_number_alt} `}
                    </a>
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
            <React.Fragment>
              {currentBigAd.email ? (
                <div className="flex space-x-3 items-center">
                  <MailIcon className="block h-7 w-7" />
                  <a href={`mailto:${currentBigAd.email}`}>
                    {currentBigAd.email}
                  </a>
                </div>
              ) : null}
            </React.Fragment>
            <React.Fragment>
              {currentBigAd.website ? (
                <div className="flex space-x-3 items-center">
                  <GlobeIcon className="block h-7 w-7" />
                  <a href={currentBigAd.website} rel="noopener noreferrer">
                    {currentBigAd.website}
                  </a>
                </div>
              ) : null}
            </React.Fragment>
            <React.Fragment>
              {currentBigAd.facebook_url ? (
                <div className="flex space-x-3 items-center">
                  <AiFillFacebook className="block h-7 w-7" />
                  <a href={currentBigAd.facebook_url} rel="noopener noreferrer">
                    {currentBigAd.facebook_url}
                  </a>
                </div>
              ) : null}
            </React.Fragment>
            <ReactPlayer
              url={currentBigAd.video_url}
              width="100%"
              height="auto"
            />
          </div>
          <div className="w-full md:w-2/3 overflow-x-auto space-y-0 md:space-y-2">
            <h5 className="text-primary-default">
              {currentBigAd.description_heading}
            </h5>
            <p className="text-sm text-gray-500">{currentBigAd.description}</p>
            <h5 className="text-primary-default">
              {currentBigAd.about_heading}
            </h5>
            <p className="text-sm text-gray-500">
              {currentBigAd.about_description}
            </p>
          </div>
        </div>
      </Modal>

      <AppHeading>Offerings</AppHeading>
      <AppSubheading>Your latest discounts and offerings</AppSubheading>
      <ThemeProvider theme={theme}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="offerings tab"
          style={{ padding: 0 }}
        >
          {tabs.map((tab, idx) => (
            <Tab
              style={{ textTransform: 'none' }}
              label={tab.label}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </ThemeProvider>

      <TabPanel value={tabValue} index={0}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
          {foodOfferingItems.map(item => (
            <ItemCard key={item.name} item={item} image />
          ))}
          <React.Fragment>
            {isFetchingBigAds ? (
              <React.Fragment>
                {[...Array(12).keys()].map(() => (
                  <div className="w-auto h-48 border-2 rounded-md mx-auto mt-20">
                    <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
                      <div className="w-12 bg-gray-300 h-12 rounded-full " />
                      <div className="flex flex-col space-y-3">
                        <div className="w-36 bg-gray-300 h-6 rounded-md " />
                        <div className="w-24 bg-gray-300 h-6 rounded-md " />
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {bigAds
                  .filter(item => item.offering_type !== 'business')
                  .map(ad => (
                    <BigAdsCard
                      serviceProvider={ad}
                      setShowModal={setShowModal}
                      setCurrentBigAd={setCurrentBigAd}
                    />
                  ))}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
          {isFetchingBigAds ? (
            <React.Fragment>
              {[...Array(12).keys()].map(() => (
                <div className="w-auto h-48 border-2 rounded-md mx-auto mt-20">
                  <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
                    <div className="w-12 bg-gray-300 h-12 rounded-full " />
                    <div className="flex flex-col space-y-3">
                      <div className="w-36 bg-gray-300 h-6 rounded-md " />
                      <div className="w-24 bg-gray-300 h-6 rounded-md " />
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {bigAds
                .filter(item => item.offering_type !== 'food')
                .map(ad => (
                  <BigAdsCard
                    serviceProvider={ad}
                    setShowModal={setShowModal}
                    setCurrentBigAd={setCurrentBigAd}
                  />
                ))}
            </React.Fragment>
          )}
        </div>
      </TabPanel>
    </div>
  )
}

export default UserOfferingsView
