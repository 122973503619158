import { useQuery, useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from '../config/constants'

import { Axios } from '../config/axios'

// Hooks
import { useUser } from './useUser'
import { sendEmail } from '../utils/functions.js'

const getUserBookings = async userId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Bookings',
    params: {
      user: `eq.${userId}`,
    },
  })

  return data
}

const createBooking = async payload => {
  await Axios({
    method: 'POST',
    url: '/Bookings',
    data: payload,
  })

  console.log(payload)

  try {
    await sendEmail({
      from: 'noreply@conciergedeskonline.com',
      to: payload.service_provider_email,
      subject: 'Digital Concierge Desk: New appointment',
      text: 'You have a new appointment on the Concierge desk. Login and view the appointment.',
    })
  } catch (e) {
    toast.error(e)
  }
}

export const useBookings = resetForm => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  // Data fetchers
  const { data: userBookings, isFetching: isFetchingUserBookings } = useQuery(
    QUERY_KEYS.BOOKINGS,
    () => getUserBookings(user.id)
  )

  // Mutations
  const { mutate: createBookingMutation } = useMutation(
    payload => createBooking(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.BOOKINGS)
        resetForm()
        toast.success('Booking confirmed!')
      },
      onError: () => {
        toast.error('Sorry, could not process your booking. Please try again.')
      },
    }
  )

  return {
    userBookings,
    isFetchingUserBookings,
    createBookingMutation,
  }
}
