import dayjs from 'dayjs'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from '../config/constants'

import { Axios } from '../config/axios'
import { sendEmail } from '../utils/functions.js'

import { useUser } from './useUser'

export const getVoucherHistory = async userId => {
  console.log(userId)
  const { data } = await Axios({
    method: 'GET',
    url: '/RequestedVouchers',
    params: {
      user: `eq.${userId}`,
    },
  })

  // Return vouchers sorted by latest date

  if (data) {
    return data
      .slice()
      .sort((a, b) => b.date - a.date)
      .reverse()
  }

  return []
}

export const requestVoucher = async ({
  id: userId,
  first_name,
  email_address,
}) => {
  // Fetch voucher from Vouchers table
  const { data } = await Axios({
    method: 'GET',
    url: '/Vouchers',
    params: {
      select: '*',
      limit: 1,
    },
  })

  const newVoucher = data[0]

  // Add voucher to RequestedVouchers table
  await Axios({
    method: 'POST',
    url: '/RequestedVouchers',
    data: {
      voucher_id: newVoucher.voucher_id,
      wi_code: newVoucher.wi_code,
      user: userId,
    },
  })

  // Remove voucher from Vouchers Table
  await Axios({
    method: 'DELETE',
    url: '/Vouchers',
    params: {
      id: `eq.${newVoucher.id}`,
    },
  })

  try {
    await sendEmail({
      from: 'vouchers@conciergedeskonline.com',
      to: email_address,
      subject: `Nandos Voucher: ${dayjs(new Date()).format('DD MMMM YYYY')} `,
      text: `Hello ${first_name}! Your Nandos voucher is: ${newVoucher.wi_code}`,
    })
  } catch (e) {
    toast.error(
      'Ooops. Something went wrong – we could not email your voucher to you.'
    )
  }
}

export const useVouchers = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()
  const { data: voucherHistory, isFetching: isFetchingVouchers } = useQuery(
    QUERY_KEYS.VOUCHER_HISTORY,
    () => getVoucherHistory(user.id)
  )
  const { mutate: requestVoucherMutation } = useMutation(
    () => requestVoucher(user),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QUERY_KEYS.VOUCHER_HISTORY)
        toast.success('Voucher successfully requested!')
      },
    }
  )

  return {
    requestVoucherMutation,
    voucherHistory,
    isFetchingVouchers,
  }
}

export const usePrefetchVoucherHistory = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(QUERY_KEYS.VOUCHER_HISTORY, () =>
    getVoucherHistory(user.id)
  )
}
