import React from 'react'
import DatePicker from 'react-datepicker'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-datepicker/dist/react-datepicker.css'

// Components
import { AppSubheading } from '../../components/shared/AppSubheading'
import { AppHeading } from '../../components/shared/AppHeading'
import { ItemCard } from '../../components/shared/ItemCard'
import { SlideOver } from '../../components/shared/SlideOver'
import { FormRadioGroup, FormInput } from '../../components/form'

// Hooks
import { useServiceProviders } from '../../hooks/useServiceProviders'
import { SubHeading } from '../../components/shared/CopyComponents'
import { useUser } from '../../hooks/useUser'
import { useBookings } from '../../hooks/useBookings'

// Utils
import {
  getServiceProviderConfig,
  isTuesdayThurdsay,
  filterPassedTime,
  isWeekday,
} from '../../utils/functions'
import { LOCATION_OPTIONS } from '../../utils/constants'

const DateTimeInput = ({ startDate, setStartDate, label, ...otherProps }) => (
  <div>
    <label htmlFor="date-time">{label}</label>
    <DatePicker
      selected={startDate}
      onChange={date => setStartDate(date)}
      showTimeSelect
      dateFormat="MMMM d, yyyy h:mm aa"
      minTime={setHours(setMinutes(new Date(), 0), 9)}
      maxTime={setHours(setMinutes(new Date(), 0), 16)}
      filterTime={filterPassedTime}
      {...otherProps}
    />
  </div>
)

const UserServicesView = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm()
  const [showSlideOver, setShowSlideOver] = React.useState(false)
  const [selectedService, setSelectedService] = React.useState({})
  const [date, setDate] = React.useState(null)
  const [pickupTime, setPickupTime] = React.useState(null)
  const [paymentOption, setPaymentOption] = React.useState('')
  const [locationOption, setLocationOption] = React.useState({ name: '' })
  const { serviceProviders } = useServiceProviders()
  const { user } = useUser()
  const { createBookingMutation } = useBookings(resetForm)

  function resetForm() {
    setDate(null)
    setShowSlideOver(false)
    reset({
      phoneNumber: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
    })
  }

  const handleBooking = values => {
    if (!date) {
      toast.error('Please select a date and time for your appointment')
      return
    }
    if (!paymentOption.name) {
      toast.error('Please select a payment method')
      return
    }
    if (!locationOption.name) {
      toast.error('Please select a location')
      return
    }

    const payload = {
      address: JSON.stringify(values),
      amount: 1000,
      booking_time: date,
      pickup_time: pickupTime,
      on_campus: locationOption.name === LOCATION_OPTIONS.CAMPUS,
      paid: false,
      payment_method: paymentOption.name,
      service_provider: selectedService.id,
      service_offering: selectedService.name,
      full_name: `${user.first_name} ${user.last_name}`,
      email_address: user.email_address,
      service_provider_email: selectedService.email,
      user: user.id,
    }

    createBookingMutation(payload)
  }

  React.useEffect(() => {
    console.log(selectedService)
  }, [selectedService.id])

  return (
    <React.Fragment>
      <SlideOver
        show={showSlideOver}
        setShow={setShowSlideOver}
        title="Make an appointment"
        subtitle="Choose a timeslot and make a booking"
        actionButtonLabel="Make Booking"
        handleSubmit={handleSubmit}
        handleFormSubmitFunc={handleBooking}
      >
        {selectedService.copy}
        <div className="flex justify-between my-4">
          <DateTimeInput
            label="Drop off time"
            startDate={date}
            setStartDate={setDate}
            filterDate={
              selectedService.name === 'Car Wash'
                ? isWeekday
                : isTuesdayThurdsay
            }
          />
          <DateTimeInput
            label="Pick up time"
            startDate={pickupTime}
            setStartDate={setPickupTime}
            filterDate={
              selectedService.name === 'Car Wash'
                ? isWeekday
                : isTuesdayThurdsay
            }
          />
        </div>
        <div className="my-2">
          <SubHeading>Choose Payment Method</SubHeading>
          <FormRadioGroup
            options={selectedService.paymentOptions}
            selected={paymentOption}
            setSelected={setPaymentOption}
          />
        </div>
        <div className="my-2">
          <SubHeading>Choose Location</SubHeading>
          <FormRadioGroup
            options={selectedService.locationOptions}
            selected={locationOption}
            setSelected={setLocationOption}
          />
        </div>
        {locationOption.name === LOCATION_OPTIONS.CAMPUS && (
          <div className="my-3">
            <SubHeading>Personal Information</SubHeading>
            <FormInput
              id="phoneNumber"
              errors={errors}
              label="Phone Number"
              register={register}
              required
            />
          </div>
        )}
        {locationOption.name === LOCATION_OPTIONS.HOME && (
          <div className="my-3 space-y-2">
            <SubHeading>Personal Information</SubHeading>
            <FormInput
              id="phoneNumber"
              errors={errors}
              label="Phone Number"
              register={register}
              required
            />
            <SubHeading>Address Information</SubHeading>
            <FormInput
              id="address"
              label="Address"
              errors={errors}
              register={register}
              required
            />
            <FormInput
              id="city"
              label="City"
              errors={errors}
              register={register}
              required
            />
            <FormInput
              id="province"
              label="Province"
              errors={errors}
              register={register}
              required
            />
            <FormInput
              id="postalCode"
              label="Postal Code"
              errors={errors}
              register={register}
              required
            />
            <FormInput
              id="country"
              label="Country"
              errors={errors}
              register={register}
              required
            />
          </div>
        )}
      </SlideOver>

      <div>
        <AppHeading>Services</AppHeading>
        <AppSubheading>Services at your convenience</AppSubheading>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
          {getServiceProviderConfig().map(item => (
            <ItemCard
              key={item.name}
              item={item}
              icon
              setShowSlideOver={setShowSlideOver}
              additionalSetter={setSelectedService}
              id={serviceProviders?.filter(sp => sp.name === item.name)[0].id}
              email={
                serviceProviders?.filter(sp => sp.name === item.name)[0].email
              }
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserServicesView
