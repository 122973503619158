export const QUERY_KEYS = {
  USER: 'user',
  SERVICE_PROVIDERS: 'service_providers',
  BIG_ADS: 'big_ads',
  SESSION: 'session',
  ORDERS: 'orders',
  ORDER: 'order',
  PRODUCTS: 'products',
  VENDORS: 'vendors',
  VENDOR: 'vendor',
  CART: 'cart',
  BOOKINGS: 'bookings',
  BOOKING: 'booking',
  VOUCHERS: 'vouchers',
  VOUCHER_HISTORY: 'voucher_history',
  SEATTLE_OPT_INS: 'seattle_opt_ins',
  MOTHERLAND_OPT_INS: 'motherland_opt_ins',
}
