import * as Yup from 'yup'
import dayjs from 'dayjs'
import axios from 'axios'
import { CarWashCopy } from '../components/shared/CarWashCopy'

// Icons
import { MdOutlineLocalCarWash } from 'react-icons/md'

// Constants
import { PAYMENT_METHODS, LOCATION_OPTIONS } from './constants'

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const formatPrice = price => {
  return `R ${Number(price)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

// Voucher disbursement functions
export const getDaysSinceLastCoupon = lastRequestedDate => {
  const today = new Date()
  return (today.getTime() - lastRequestedDate.getTime()) / (1000 * 3600 * 24)
}

export const isMonday = () => {
  const today = new Date()
  return today.getDay() === 1
}

export const canRequestCoupon = lastRequestedDate => {
  const today = new Date()
  return (
    (isMonday && lastRequestedDate.getDate() !== today.getDate()) ||
    getDaysSinceLastCoupon(lastRequestedDate) > 7
  )
}

export const filterPassedTime = time => {
  const currentDate = new Date()
  const selectedDate = new Date(time)

  return currentDate.getTime() < selectedDate.getTime()
}

export const isTuesdayThurdsay = date => {
  const day = dayjs(date).get('day')
  return day === 2 || day === 4
}

export const isWeekday = date => {
  const day = dayjs(date).get('day')
  return day === 1 || day === 2 || day === 3 || day === 4 || day === 5
}

export const getServiceProviderConfig = () => {
  return [
    {
      name: 'Car Wash',
      icon: MdOutlineLocalCarWash,
      copy: <CarWashCopy />,
      action: '',
      paymentOptions: [
        {
          name: PAYMENT_METHODS.KIOSK,
          description: 'Pay at the kiosk on campus',
        },
        {
          name: PAYMENT_METHODS.PAYROLL,
          description: 'Download and complete the form and submit to HR',
        },
      ],
      locationOptions: [
        {
          name: LOCATION_OPTIONS.CAMPUS,
          description: 'Hollard Parktown campus',
        },
      ],
      formInitialValues: {
        address: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'South Africa',
        cellphoneNumber: '',
      },
      formValidationSchema: Yup.object({
        address: Yup.string().required().label('Street address'),
        city: Yup.string().required().label('City'),
        province: Yup.string().required().label('Province'),
        postalCode: Yup.string().max(4).min(4).required().label('Postal Code'),
        country: Yup.string().required().label('Country'),
        cellphoneNumber: Yup.number()
          .min(10)
          .max(10)
          .required()
          .label('Cellphone Number'),
      }),
    },
  ]
}

export const sendEmail = async emailBody => {
  const formData = new FormData()
  for (const key in emailBody) {
    formData.append(key, emailBody[key])
  }

  await axios({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    auth: {
      username: 'api',
      password: process.env.REACT_APP_MAILGUN_API_KEY,
    },
    url: process.env.REACT_APP_MAILGUN_API_URL,
  })
}
