import { useQuery, useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from '../config/constants'

import { Axios } from '../config/axios'

// Hooks
import { useUser } from './useUser'

const getMotherlandOptIn = async userId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/MotherlandOptIns',
    params: {
      user: `eq.${userId}`,
    },
  })

  if (!data) return {}

  return data[0]
}

const getSeattleOptIn = async userId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/SeattleOptIns',
    params: {
      user: `eq.${userId}`,
    },
  })

  if (!data) return {}

  return data[0]
}

const optInSeattle = async payload => {
  await Axios({
    method: 'POST',
    url: '/SeattleOptIns',
    data: payload,
  })
}

const optInMotherland = async payload => {
  await Axios({
    method: 'POST',
    url: '/MotherlandOptIns',
    data: payload,
  })
}

export const useOptIns = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()

  // Getters
  const { data: motherlandOptIn, isFetching: isFetchingMotherlandOptIn } =
    useQuery(QUERY_KEYS.MOTHERLAND_OPT_INS, () => getMotherlandOptIn(user.id))

  const { data: seattleOptIn, isFetching: isFetchingSeattleOptIn } = useQuery(
    QUERY_KEYS.SEATTLE_OPT_INS,
    () => getSeattleOptIn(user.id)
  )

  // Setters
  const { mutate: optInSeattleMutation } = useMutation(
    payload => optInSeattle(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.SEATTLE_OPT_INS)
        toast.success(
          'You have successfully opted in to receive Seattle Coffee vouchers!'
        )
      },
    }
  )

  const { mutate: optInMotherlandMutation } = useMutation(
    payload => optInMotherland(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.MOTHERLAND_OPT_INS)
        toast.success(
          'You have successfully opted in to receive Motherland Coffee vouchers!'
        )
      },
    }
  )
  return {
    motherlandOptIn,
    isFetchingMotherlandOptIn,
    seattleOptIn,
    isFetchingSeattleOptIn,
    optInSeattleMutation,
    optInMotherlandMutation,
  }
}

export const usePrefetchMotherlandOptIn = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(QUERY_KEYS.MOTHERLAND_OPT_INS, () =>
    getMotherlandOptIn(user.id)
  )
}

export const usePretchSeattleOptIn = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(QUERY_KEYS.SEATTLE_OPT_INS, () =>
    getSeattleOptIn(user.id)
  )
}
