import React from 'react'
import { Router, Redirect } from '@reach/router'

// Import views
import UserBookingsView from '../views/user/UserBookingsView'
import UserBookingView from '../views/user/UserBookingView'
import UserHomeView from '../views/user/UserHomeView'
import UserMotherlandView from '../views/user/UserMotherlandView'
import UserNandosView from '../views/user/UserNandosView'
import UserOrdersView from '../views/user/UserOrdersView'
import UserOrderView from '../views/user/UserOrderView'
import UserSeattleView from '../views/user/UserSeattleView'
import UserServicesView from '../views/user/UserServicesView'
import UserShopView from '../views/user/UserShopView'
import UserVendorsView from '../views/user/UserVendorsView'
import UserOfferingsView from '../views/user/UserOfferingsView'
import LoginView from '../views/shared/LoginView'
import UserCartView from '../views/user/UserCartView.jsx'
import UserCheckoutView from '../views/user/UserCheckoutView.jsx'
import UserNotFoundView from '../views/user/UserNotFoundView.jsx'

// Constants
import { ROUTES } from './index'

// Hooks
import { useUser } from '../hooks/useUser'

export const UserRouter = () => {
  const { user } = useUser()
  return (
    <Router primary={false}>
      <LoginView path={ROUTES.SHARED.LOGIN} />
      <UserNotFoundView default />
      {user ? (
        <>
          <UserHomeView path={ROUTES.USER.HOME} />
          <UserBookingsView path={ROUTES.USER.BOOKINGS} />
          <UserBookingView path={ROUTES.USER.BOOKING} />
          <UserNandosView path={ROUTES.USER.NANDOS} />
          <UserOrdersView path={ROUTES.USER.ORDERS} />
          <UserOrderView path={ROUTES.USER.ORDER} />
          <UserSeattleView path={ROUTES.USER.SEATTLE} />
          <UserServicesView path={ROUTES.USER.SERVICES} />
          <UserShopView path={ROUTES.USER.SHOP} />
          <UserVendorsView path={ROUTES.USER.VENDORS} />
          <UserOfferingsView path={ROUTES.USER.OFFERINGS} />
          <UserCartView path={ROUTES.USER.CART} />
          <UserCheckoutView path={ROUTES.USER.CHECKOUT} />
        </>
      ) : (
        <Redirect
          from={window.location.pathname}
          to={ROUTES.SHARED.LOGIN}
          noThrow
        />
      )}
    </Router>
  )
}
