import React from 'react'
import { useIsMutating } from 'react-query'
import { PuffLoader } from 'react-spinners'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Icons
import { GrAppleAppStore } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'
import { RiCoupon2Fill as VoucherIcon } from 'react-icons/ri'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { Button } from '../../components/shared/Button'

import { LOGOS } from '../../utils/constants'
import { canRequestCoupon, sendEmail } from '../../utils/functions'
import { BackButton } from '../../components/shared/BackButton.jsx'

// Hooks
import { useVouchers } from '../../hooks/useVouchers'

dayjs.extend(relativeTime)

const UserNandosView = () => {
  const { voucherHistory, requestVoucherMutation, isFetchingVouchers } =
    useVouchers()
  const isMutating = useIsMutating()
  return (
    <div className="pb-20">
      <BackButton />
      <AppHeading>Food and beverage offerings</AppHeading>
      <AppSubheading>Your latest discounts and offerings</AppSubheading>
      <div className="flex flex-col md:flex-row space-y-2 md:space-x-4 my-4 items-center">
        <div className="bg-white p-8">
          <img className="block w-auto h-28" src={LOGOS.NANDOS} alt="logo" />
        </div>
        <div>
          <h1 className="text-primary-default text-2xl">
            Wow! Four (4) 25% discount vouchers every month!
          </h1>
          <p className="text-gray-700">
            Hollardites we have partnered with Nandos to bring you a little of
            the benefits you experience in the office to the comfort of wherever
            you are working.
            <br />
            <br />
            Yes, now you can get a quality, nutritious meal at an affordable
            price from Nandos.
          </p>
        </div>
      </div>
      <div>
        <h1 className="text-primary-default text-xl">Here's how:</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-4">
          {/* Step 1 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                1.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Request Voucher
              </h2>
            </div>
            <p className="text-primary-default">
              Click on the "Request voucher" button to receive your voucher.
              This voucher entitles you to a 25% discount on{' '}
              <strong>ANY MEAL</strong>, once a week. You will only receive{' '}
              <strong>25%</strong> on up to R100 worth of spend. The balance of
              your spend will not be discounted but your spend is not limited.
            </p>
            <Button
              onClick={() => requestVoucherMutation()}
              disabled={
                !isFetchingVouchers &&
                !canRequestCoupon(new Date(voucherHistory[0]?.created_at))
              }
            >
              Request Voucher
            </Button>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                2.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Redeem Voucher
              </h2>
            </div>
            <p className="text-primary-default">
              Go to the Nandos app, website or any Nandos store to redeem your
              discount voucher.
            </p>
            <a
              href="https://www.nandos.co.za/eat/restaurants"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button tertiary>Find your nearest Nandos</Button>
            </a>
          </div>
          {/* Step 3 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                3.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Download App
              </h2>
            </div>
            <p className="text-primary-default">
              Download the Nandos app for your iPhone or Android device.
            </p>
            <div className="flex justify-around text-tertiary-default">
              <a
                href="https://apps.apple.com/za/app/nandos-south-africa/id1316416867"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center hover:text-tertiary-mid"
              >
                <GrAppleAppStore size={60} />
                <span className="uppercase font-bold text-sm">
                  Go to App Store
                </span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=co.za.nandos.nandosapp&hl=en_ZA&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center hover:text-tertiary-mid"
              >
                <FaGooglePlay size={60} />
                <span className="uppercase font-bold text-sm">
                  Go to Play Store
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {voucherHistory?.length > 0 && (
        <div className="flex flex-col space-y-4 text-tertiary-default bg-tertiary-light w-full lg:w-1/3 p-8 my-3">
          <div className="flex space-x-4 items-center">
            <VoucherIcon size={60} />
            {isFetchingVouchers || isMutating ? (
              <PuffLoader color={'#44b4a6'} size={100} />
            ) : (
              <div className="text-primary-default flex flex-col">
                {voucherHistory && (
                  <React.Fragment>
                    <h4 className="text-lg">
                      <strong>Voucher Code: {'  '} </strong>
                      <span>{voucherHistory[0]?.wi_code}</span>
                    </h4>
                    <span className="text-sm">{`Requested ${dayjs(
                      voucherHistory[0]?.created_at
                    ).fromNow()}`}</span>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          {!isFetchingVouchers && !isMutating ? (
            <p>
              Your voucher has been sent to your Hollard email address. This
              voucher expires in the month that it has been redeemed. You can
              request another voucher next week Monday
            </p>
          ) : null}
        </div>
      )}

      <div className="space-y-6">
        <h1 className="text-primary-default text-xl">Remember the following</h1>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            You can redeem 4 x 25% discount vouchers, per month, but only once a
            week here on the Digital Concierge Desk.
          </li>
          <li>
            This discount voucher is available to be used online (Nandos app or
            website) and in store
          </li>
        </ul>
        <div className="w-full lg:w-1/4">
          <a
            href="https://www.nandos.co.za/eat/order/menu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button tertiary>View Menu</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default UserNandosView
