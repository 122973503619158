import React from 'react'
import { toast } from 'react-toastify'
import { PuffLoader } from 'react-spinners'
import { useIsMutating } from 'react-query'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import relativeTime from 'dayjs/plugin/relativeTime'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { Button } from '../../components/shared/Button'
import { SlideOver } from '../../components/shared/SlideOver'
import { FormCheckbox, FormInput } from '../../components/form'
import { PopiaCopy } from '../../components/shared/PopiaCopy'

import { LOGOS } from '../../utils/constants'
import { BackButton } from '../../components/shared/BackButton.jsx'

// Hooks
import { useOptIns } from '../../hooks/useOptIns'
import { useUser } from '../../hooks/useUser'

// Utils
import { getStoredUser } from '../../local-storage/userStorage'

dayjs.extend(relativeTime)

const UserMotherlandView = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [showSlideOver, setShowSlideOver] = React.useState(false)
  const {
    motherlandOptIn,
    optInMotherlandMutation,
    isFetchingMotherlandOptIn,
  } = useOptIns()
  const { user } = useUser()
  const isMutating = useIsMutating()

  const userInfo = getStoredUser()

  const handleOptIn = values => {
    const payload = {
      user: user.id,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
    }
    if (!values.terms) {
      toast.error('You have to accept the terms checkbox before you opt in')
    } else {
      console.log(payload)
      optInMotherlandMutation(payload)
    }
  }

  return (
    <React.Fragment>
      <SlideOver
        show={showSlideOver}
        setShow={setShowSlideOver}
        title="Opt in"
        subtitle="Read and accept the terms and conditions and fill in the form below"
        actionButtonLabel="Opt In"
        disableActionButton={motherlandOptIn}
        handleSubmit={handleSubmit}
        handleFormSubmitFunc={handleOptIn}
      >
        <div className="space-y-10">
          <PopiaCopy />
          {/* Opt in details */}
          {motherlandOptIn && (
            <div className="p-6 bg-tertiary-light text-tertiary-default">
              You opted in {dayjs(motherlandOptIn.created_at).fromNow()}
            </div>
          )}

          {/* Loading state */}
          {isFetchingMotherlandOptIn || isMutating ? (
            <div className="flex items-center justify-center">
              <PuffLoader color="#44b4a6" size={200} />
            </div>
          ) : null}

          {/* Form begins  */}
          {!motherlandOptIn && !isFetchingMotherlandOptIn && !isMutating && (
            <div className="py-4 space-y-3">
              <FormInput
                id="firstName"
                errors={errors}
                label="First Name"
                register={register}
                required
                defaultValue={userInfo.first_name || ''}
              />
              <FormInput
                id="lastName"
                errors={errors}
                label="Last Name"
                register={register}
                required
                defaultValue={userInfo.last_name || ''}
              />
              <FormInput
                id="email"
                errors={errors}
                label="Email"
                register={register}
                required
                defaultValue={userInfo.email_address || ''}
              />
              <FormInput
                id="phoneNumber"
                errors={errors}
                label="Phone Number"
                register={register}
                required
              />
              <FormCheckbox
                label="I read the terms and conditions"
                id="terms"
                register={register}
                errors={errors}
                required
              />
            </div>
          )}
        </div>
      </SlideOver>

      <div>
        <BackButton />
        <AppHeading>Food and beverage offerings</AppHeading>
        <AppSubheading>Your latest discounts and offerings</AppSubheading>
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-x-4 my-4 items-center">
          <div className="bg-white p-8 lg:w-1/4">
            <img
              className="block w-auto h-28"
              src={LOGOS.MOTHERLAND}
              alt="logo"
            />
          </div>
          <div>
            <h1 className="text-primary-default text-2xl">
              Need that coffee fix? Three (3) R15 discount vouchers every month!
            </h1>
            <p className="text-gray-700">
              Hollardites we have partnered with Motherland Coffee Co. to bring
              you a little of the benefits you experienced in the office to the
              comfort of wherever you are working. Yes, now you can get your
              regular coffee fix or any other beverage they have available at a
              R15 discounted rate, <strong>THREE TIMES PER MONTH</strong>.{' '}
              <br /> <br /> For you to benefit from this fantastic benefit you
              need to “Opt In” so that we may send your personal information to
              our amazing partners Motherland Coffee Co.
            </p>
          </div>
        </div>
        <div className="space-y-3">
          <h1 className="text-primary-default text-xl">Here's how:</h1>
          <ol className="list-decimal list-inside text-gray-700">
            <li>
              For you to benefit from this fantastic benefit you need to “Opt
              In” so that we may send your personal information to our amazing
              partners Motherland Coffee Co.
            </li>
            <li>The good news is … you only have to do this once!</li>
            <li>
              Will they share your information with anyone else – absolutely
              not! What information are you giving them permission to have and
              why:
              <ul className="list-disc list-inside">
                <li>
                  They will have your name, surname and cell phone number.
                </li>
                <li>
                  Each month on the 1st of every month Motherland will allocate
                  against your name, 3 unique Motherland Coffee Co. R15 discount
                  vouchers to redeem at any time during that particular month
                  when you visit any of their shops. Isn't that just a fantastic
                  Work From Anywhere benefit!
                </li>
              </ul>
            </li>
            <li>
              Now that you know why you need to “Opt In” please click on the
              button at the bottom of the page to “Opt In”.
            </li>
          </ol>
        </div>
        <div className="my-3 space-y-4">
          <h1 className="text-primary-default text-xl">
            Remember the following
          </h1>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              On the 1st of every month, Motherland will allocate to you 3 x R15
              discount vouchers.
            </li>
            <li>
              This discount voucher code is only applicable if you physically go
              to your nearest Motherland Coffee Co or Fresh Stop and redeem it.
              See where your nearest Motherland is by clicking here:{' '}
              <a
                href="https://www.motherlandcoffee.com/locations/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-tertiary-default"
              >
                Find your nearest Motherland.
              </a>
            </li>
          </ul>
          <div className="w-full lg:w-1/4">
            <Button onClick={() => setShowSlideOver(true)} tertiary>
              Opt in
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserMotherlandView
